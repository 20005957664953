import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function General({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('General');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>General</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default General;

const DATA = [
    {
        title: 'Is the liquidity locked?',
        content: 'Yes, HedgePay utilizes our proprietary zero-risk liquidity locking service. Liquidity is locked biannually and will be automatically renewed 5 days before expiration if no breaking event occurs. '
    },
    {
        title: 'Where can I buy HedgePay?',
        content: 'HedgePay is available for purchase utilizing our in-house Aggregator DEX with the ability to track the best prices token. <a href="https://hedgepay.org/dex">https://hedgepay.org/dex</a>'
    },
    {
        title: 'Where can I find the whitepaper?',
        content: 'HedgePay whitepaper, visit <a href="https://hedgepay.org/whitepaper">https://hedgepay.org/whitepaper</a>'
    },
    {
        title: 'HedgePay is available on which network?',
        content: 'Currently HedgePay is available on Binance Smart Chain. HedgePay will migrate to ETH, WDOGE, NEAR and AVAX'
    },
    {
        title: 'How much is HedgePay transfer fee?',
        content: 'The transfer fee is 0% using transfer gateway, visit <a href="https://hedgepay.org/transfer">https://hedgepay.org/transfer</a>'
    },
    {
        title: 'What is the official contract address of HedgePay?',
        content: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a'
    },
    {
        title: 'What is the fee for buying and selling HedgePay?',
        content: 'Yes. HedgePay buy fees is 4% and sell fees is 4%'
    },
    {
        title: 'Where can i find HedgePay supply distribution info?',
        content: 'For supply distribution infomation visit <a href="/">Google Doc</a> sharing'
    },
    {
        title: 'Does HedgePay hold any reserve supply?',
        content: 'No. During our initial EIO phases, our team utilized this opportunity to purchase HedgePay with personal money.'
    },
    {
        title: 'Where is HedgePay Incorporated?',
        content: 'HedgePay is incorporated in Malaysia under the entity name of HedgePay Sdn. Bhd.'
    },
    {
        title: 'Where can i find HedgePay Team infomation?',
        content: 'Meet the team behind HedgePay, visit <a href="https://hedgepay.org/team">https://hedgepay.org/team</a>'
    },
    {
        title: 'Is HedgePay audited?',
        content: 'Yes. HedgePay is audited by <a href="/">CERTIK</a>, <a href="/">Dessert Finance</a>, <a href="/">SpyWolf</a> and <a href="/">inhouse audit</a>'
    },
    {
        title: 'It’s safe in HedgePay?',
        content: 'Yes. HedgePay security protocols center on smart contracts to secure transactions on the blockchain. These smart contracts built on a modular and secure architecture provide the highest levels of security, keep your funds safe, and eliminate intermediaries. Therefore, the HedgePay protocol has been designed to mitigate security vulnerabilities in both environments.'
    },
    {
        title: 'Can HedgePay mint tokens?',
        content: 'Yes. HedgePay is utilizing minting for future bridging protocols. In order to ensure stable price between blockchains, we will be minting a token on one chain and burning on the other to ensure circulating supply never exceeds 1 billion HPAY. This will be vital to our operations going forward as we expand. Minting is controlled by the multi-signature wallet, so no single entity can mint HPAY for any other purposes.'
    },
]